import React, { useContext} from 'react'
import {create} from "./App"
function Homepage(props) {
    const {setMsgs,long,lat} = useContext(create)
    const saveLocation=()=>{
            const saving = {
            long:long,
            lat:lat,
            time:new Date().toISOString().substring(0,10)
            };
            localStorage.setItem("SavedLocation",JSON.stringify(saving))
            setMsgs({
                type:"green",
                message:`Car location saved. You can always find your car by clicking the find a car buttton.Location lat:${lat},long:${long}`,
                show:true
            });
        };
         
       
    
 
  return (
    <div>

<div class="relative bg-white overflow-hidden">
  <div class="max-w-7xl mx-auto">
    <div class="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
      <svg class="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
        <polygon points="50,0 100,0 50,100 0,100" />
      </svg>

      <div>
        <div class="relative pt-6 px-4 sm:px-6 lg:px-8">
          <nav class="relative flex items-center justify-between sm:h-10 lg:justify-start" aria-label="Global">
            <div class="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
              <div class="flex items-center justify-between w-full md:w-auto">
                <a href="#">
                  <span class="sr-only"></span>
                  <img alt="Workflow" class="h-8 w-auto sm:h-10" src="A.png" style={{borderRadius:"4px"}}/>
                </a>
                <div class="-mr-2 flex items-center md:hidden">
                  <button type="button" class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" aria-expanded="false">
                    <span class="sr-only"></span>
                 
                  </button>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>

      <main class="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
        <div class="sm:text-center lg:text-left">
          <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span class="block xl:inline">Hi, welcome to</span>
            <span class="block text-indigo-600 xl:inline" style={{color:"#ddd4c9"}}>FindMyCarSpace</span>
          </h1>
          <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">There's no need to login. All information can only be accessed by you.<br/></p>
          <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
            <div class="rounded-md shadow">
              <a href="/map" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10" style={{background:"#FF8C00"}}> Find car </a>
            </div>
            <div class="mt-3 sm:mt-0 sm:ml-3">
              <a onClick={saveLocation} class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10" style={{background:"#EEE8AA",color:"#FF8C00",cursor:"pointer"}}>Save car space </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
  <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
    <img class="h-56 mx-auto w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full rounded-lg my-3" src="A.gif" alt="findmycarspace" />
  </div>
</div>





<div class="py-12 bg-white">
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <div class="lg:text-center">
      <h2 class="text-base text-indigo-600 font-semibold tracking-wide uppercase" style={{color:"#FF8C00"}}>Features</h2>
      <p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">An easy way to find your car space</p>
      <p class="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">We provide essential tools to help user find their car parking space in a wide area easily at any time</p>
    </div>

    <div class="mt-10">
      <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
        <div class="relative">
          <dt>
            <div style={{background:"#EEE8AA",color:"#FF8C00"}} class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
            </svg>
            </div>
            <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Anonymous</p>
          </dt>
          <dd class="mt-2 ml-16 text-base text-gray-500">To find or save car space is Anonymous. There's no need for any authentication to verify a user.</dd>
        </div>

        <div class="relative">
          <dt>
            <div style={{background:"#EEE8AA",color:"#FF8C00"}} class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
             <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
             <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
             </svg>
            </div>
            <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Free access</p>
          </dt>
          <dd class="mt-2 ml-16 text-base text-gray-500">
          To use findmycarspace is free. No charges are applied. </dd>
        </div>

        <div class="relative">
          <dt>
            <div style={{background:"#EEE8AA",color:"#FF8C00"}} class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
            </div>
            <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Predicted distance </p>
          </dt>
          <dd class="mt-2 ml-16 text-base text-gray-500">
          Findmycarspace helps you calculate real-time distance between users and their car.</dd>
        </div>

        <div class="relative">
          <dt>
            <div style={{background:"#EEE8AA",color:"#FF8C00"}} class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
              </svg>
            </div>
            <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Directions</p>
          </dt>
          <dd class="mt-2 ml-16 text-base text-gray-500">
          Findmycarspace uses integrated software to help user find the Direction easily. </dd>
        </div>
        <div class="relative">
        <dt>
        <div style={{background:"#EEE8AA",color:"#FF8C00"}} class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
       <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
       <path stroke-linecap="round" stroke-linejoin="round" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
       </svg>
        </div>
        <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Voice support</p>
        </dt>
        <dd class="mt-2 ml-16 text-base text-gray-500">
Findmycarspace provides automatic Voice support for more user experience. </dd>
        </div>
      </dl>
    </div>
  </div>
</div>
<div class="rounded-md shadow">
<a href="#" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium  text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10" style={{background:"#FF8C00"}}>Get started</a>
<p class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10" style={{background:"#FF8C00"}}>&copy; findmycarspace. All right reserved.</p>
</div>
    </div>
  )
}

export default Homepage