import React, { useEffect,useState,useContext } from 'react'
import {create} from "./App"
function Alert() {
    const {msgs} = useContext(create)
    const[show,setShow]=useState(false);
    const[time,setTime]=useState(0)
  
    const check_to_show=()=>{
        if(msgs&&msgs.show==true){
            setShow(true);
        }
    };
    useEffect(()=>{
        check_to_show()
       // console.clear()
    },[msgs])
    useEffect(()=>{
        if(time==10){
        setShow(false)
        setTime(0)
        }
        setTimeout(()=>{
          setTime(time+1)
        },1000) 
      },[time])
  return (
 <div>
     {
         show?
         <div>
             {
                 msgs.type=="red"?
                 <div class="bg-red-100 rounded-lg py-5 px-6 mb-4 font-bold text-bold text-white mb-3" role="alert">
                 {msgs&&msgs.message}
               </div>:
               <div class="bg-green-100 rounded-lg py-5 px-6 mb-4 font-bold text-bold text-white mb-3" role="alert">
               {msgs&&msgs.message}
             </div>
             }
         </div>
         :
         <div>

         </div>
     }
    </div>
  )
}

export default Alert