import React, { useEffect, useState } from "react";
import {BrowserRouter,Route,Routes} from "react-router-dom";
import Homepage from './Homepage';
import Maps from './Map';
import Alert from './Alert';
import { createContext } from "react";
export const create = createContext(null);
function App() {
  const [msgs,setMsgs]=useState(null);
  const[long,setLong]=useState(0);
  const[lat,setLat]=useState(0);
  const getGeoLocation=()=>{
    if(navigator.geolocation){
      navigator.geolocation.getCurrentPosition(function(position){
        setLong(position.coords.longitude)
        setLat(position.coords.latitude)
        console.log(position.coords.latitude)

      })
    }
     
  };
  const[time,setTime]=useState(null);
  useEffect(()=>{
    if(time==10){
      setTime(0);
    }
    setTimeout(()=>{
      setTime(time+1)
    },1000) 
  },[time])
  useEffect(()=>{
    getGeoLocation()
  },[time]);
  return (
   <div>
    <create.Provider value={{msgs,setMsgs,long,lat}}>
    <Alert data={msgs}/>
     <BrowserRouter>
     <Routes>
       <Route path="/" exact element={<Homepage data={{long,lat}}/>} />
       <Route path="/map" element={<Maps/>} />
     </Routes>
     </BrowserRouter>
     </create.Provider>
   </div>
  );
}

export default App;
