import React, { useState,useContext } from 'react';
import { withGoogleMap, GoogleMap, DirectionsRenderer } from 'react-google-maps';
import {create} from "./App"
import { useEffect } from 'react';

const GoogleMapExample = withGoogleMap(props => (
  <GoogleMap
    defaultCenter = { { lat:props.data.long, lng:props.data.long }}
    defaultZoom = { 13 }
  >
<DirectionsRenderer origin={{ lat:props.data.lat, lng:props.data.long}} destination={{ lat:props.data.desLat, lng:props.data.desLong}} />
  </GoogleMap>
));
function Maps(props){
    const[option,setOption]=useState(null)
    const syn = window.speechSynthesis;
    const [voices,setVoices]= useState([]);
    const[selectedVoice,setSelectedVoice]=useState("")
    const [distance,setDistance]=useState(null);
    const {msgs,setMsgs,lat,long} = useContext(create);
    const getCarSpace =  localStorage.getItem("SavedLocation")?JSON.parse(localStorage.getItem("SavedLocation")):{
      long:long,
      lat:lat,
      time:new Date().toISOString().substring(0,10)
      }
    const cal_Distance=()=>{
        const R = 6371e3;
        const lat2 = getCarSpace&&getCarSpace.lat
        const lat1 = lat
        const lon1 = long
        const lon2 = getCarSpace&&getCarSpace.long
        console.log(getCarSpace&&getCarSpace.lat)
         const one= lat1 * Math.PI/180;
           // φ, λ in radians const
          const  two = lat2 * Math.PI/180;
             const Δφ = (lat2-lat1) * Math.PI/180;
              const Δλ = (lon2-lon1) * Math.PI/180;
               const a = (Math.sin(Δφ/2) * Math.sin(Δφ/2)) +
               (Math.cos(one) * Math.cos(two) * (Math.sin(Δλ/2) * Math.sin(Δλ/2)));
                const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
                 const d = Math.floor( R * c) // in 
                 setDistance(d)
                 setMsgs({
                     type:"green",
                     message:`The distance between you and your car is ${d} km`,
                     show:true
                 })
     }
              useEffect(()=>{
                  cal_Distance()
              },[long,lat]);
              const showVoice=async()=>{
                 await setVoices(syn.getVoices())
                 setSelectedVoice(Math.floor(Math.random()*voices.length))
                 voices && voices.forEach((voice)=>{
                   const nn = voice.lang&&voice.lang.substring(0,8)?voice.lang.substring(0,8)+"...":voice.lang;
                   const mm = voice.name&&voice.name.substring(0,8)?voice.name.substring(0,8)+"...":voice.name;
                    const options = <select>
                      <option data-lang={voice.lang} data-name={voice.name} value={voice.name}>
                        {mm + '('+ nn +')'}
                        </option>
                    </select>
                    setOption(options)
                 })
            
               }
               useEffect(()=>{
                 showVoice()
               },[])
    const speak=()=>{
        const text = msgs&&msgs.message;
        if(syn.speaking){
          console.error("Already speaking")
          return
        }
        if(text!==''){
          const speakText = new SpeechSynthesisUtterance(text);
          speakText.onend = e=>{
            console.log("done speaking...")
          }
          speakText.onerror=e=>{
            console.log(e)
          }
          voices.forEach(voice=>{
            if(voice.name==voices[selectedVoice]){
              speakText.voice = voice;
            }
          })
          speakText.rate =1
          speakText.pitch = 0.5
          syn.speak(speakText)
        }
    };
    useEffect(()=>{
        if(msgs!==null){
            speak()
        }
    },[msgs]) ;
 
    

   return(
      <div clss="w-full h-96 rounded-lg shadow-md">
        <GoogleMapExample
        data={{long:long,lat:lat,desLong:getCarSpace&&getCarSpace.long,desLat:getCarSpace&&getCarSpace.lat}}
          containerElement={ <div style={{ height: `100%`, width: '100%' }} /> }
          mapElement={ <div style={{ height: `100vh` }} /> }
        />
      </div>
   );
      };
  export default Maps;
  //AIzaSyBjNZOQNB3Za-KV-y_TMGaddfdZ85Fd0aQ
